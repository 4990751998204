<template>
  <div class="container">
   
    <div class="d-flex justify-content-center">
      <img src="@/assets/parkit logo blue.png" class="rounded float-start" style="max-width: 300px;" />
                
    </div>
    <div class="d-flex justify-content-center">

                 <img class="rounded float-start" style="max-width: 500px;"
                 src="@/assets/home-bg.png" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
