<template>

    <ecard />

</template>

<script>
// @ is an alias to /src
import ecard from '@/components/Requests/ecard.vue'

export default {
  name: 'ECardView',
  components: {
    ecard
  }
}
</script>
