
const user = JSON.parse(localStorage.getItem('user'));
const initialState = {
    requestToReceived : null,
}

export const requestHub = {
    namespaced: true,
    state: initialState,
    actions: {
    },
    mutations: {
        requestToReceived(state, data) {
            state.requestToReceived = data;
        }
    },
    getters: {
        requestToReceived() {
           return state.requestToReceived;
        },
    }
};