<template>
    <div class="container">
        <!-- <img src="@/assets/valetParkingCard.png" class="rounded float-start" width="500px"> -->
        <div class="center">



            <div class="card" style="max-width: 30rem;">
                <img src="@/assets/valetParkingCard.png" class="card-img" alt="..." width="500px">
                <div class="card-img-overlay">



                    <h5 class="card-title text-center" style="margin-top:170px;padding-left: 35px; padding-right: 35px;">
                        خدمة إيقاف السيارة
                        <br>
                        valet parking service
                    </h5>


                    <div class="row" style="padding-left: 35px; padding-right: 35px;">

                        <template v-if="request != null">
                            <div class="text-center" style="color: red; font-weight: bold;">
                                {{ request.idStr }}
                            </div>

                            <table class="table">
                                <tr>
                                    <th>أرسلت بواسطة</th>
                                    <td>{{ request.receivedByEmployeeFullName }}</td>
                                </tr>
                                <tr>
                                    <th>أحضرت بواسطة</th>
                                    <td>{{ request.deliveredByEmployeeFullName }}</td>
                                </tr>
                                <tr>
                                    <th>نوع السيارة</th>
                                    <td>{{ request.carMakerModelName }}</td>
                                </tr>
                                <tr>
                                    <th>رقم اللوحة</th>
                                    <td>{{ request.plateNumber }}</td>
                                </tr>
                                <tr>
                                    <th>رقم الموقف</th>
                                    <td>{{ request.parkingLotNo }}</td>
                                </tr>
                                <tr>
                                    <th>وقت الدخول</th>
                                    <td>{{ dateToString }}</td>
                                </tr>
                            </table>

                            <div v-show="request.withDeliveryService">

                                <div class="d-flex  justify-content-center" v-if="request.requestStatusId == 1">
                                    <button @click="requestToReceive" class="btn btn-primary">طلب استلام السيارة</button>
                                </div>
                                <template v-else>

                                    <div class="text-center">

                                        <div class="justify-content-center">
                                            تم طلب الاستلام بتاريخ
                                        </div>

                                        <div>
                                            {{ dateFormat(request.requestToReceiveAt) }}
                                        </div>
                                       
                                        <div class="alert alert-warning alert-sm">
                                            <template
                                                v-if="formatedCountdown">
                                                {{ formatedCountdown }}
                                            </template>
                                            <template v-else>
                                                انتهى وقت الانتظار
                                            </template>
                                        </div>
                                    </div>

                                </template>


                            </div>






                        </template>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import requestService from "@/services/requestService";
import * as moment from "moment";
import "moment-duration-format";

export default {
    name: 'ecard',
    data() {
        return {
            objectKey: null,
            uuid: null,
            request: null,
            countdown: 10, // 5min
        };
    },
    computed: {
        formatedCountdown() {
            if (this.request.countDownEndAt && this.request.currentDateTime) {
                var x = new moment(this.request.countDownEndAt)
                var y = new moment(this.request.currentDateTime)
                var duration = moment.duration(x.diff(y))
                if(duration >= 0)
                {
                    return duration.format("mm:ss");
                }
                
            }
           
        },

        dateToString() {
            let result = new Date(this.request.created);

            return result.toLocaleString();
            //return result.toLocaleTimeString();
            //return result.toDateString();
        }
    },
    methods: {

        startCountDown() {
            const stopCountdown = setInterval(() => {
                //console.log("current countdown", this.countdown);
                this.countdown -= 1;
                this.request.currentDateTime = moment(this.request.currentDateTime).add(1, 'seconds');
                if (!this.countdown) clearInterval(stopCountdown);
            }, 1000);
        },
        requestToReceive: function () {
            var result = requestService.requestToReceive(this.objectKey, { objectKey: this.objectKey })
                .then(response => {
                    this.retrieveData();
                })
                .catch(e => {
                    console.log(e);
                });

        },
        dateFormat(date) {
            let result = new Date(date);

            return result.toLocaleString();
            //return result.toLocaleTimeString();
            //return result.toDateString();
        },
        /////
        retrieveData() {
            // requestService.getByObjectKey(this.objectKey)
            //     .then(response => {
            //         this.request = response.data;
            //         console.log(response.data);
            //     })
            //     .catch(e => {
            //         console.log(e);
            //     });

            requestService.getByUUID(this.uuid)
                .then(response => {
                    this.request = response.data;
                    this.objectKey = this.request.objectKey;
                    console.log(response.data);

                    this.countdown = 60 * this.request.projectPeriodWaitingMinutes;
                    if (this.request.countDownEndAt != null
                        && this.request.countDownEndAt > this.request.currentDateTime) {
                        this.startCountDown();
                    }

                })
                .catch(e => {
                    console.log(e);
                });

        },
    },
    mounted() {
        this.uuid = this.$route.params.key;
        //this.objectKey = this.$route.params.key;
        this.retrieveData();


    }
};
</script>

<style scoped lang="scss"></style>
